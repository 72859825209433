import { subscribe } from "@/services/api/Newsletter";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import Image from "next/legacy/image";
import { useCallback, useRef, useState } from "react";
import toast from "react-hot-toast";
import LucidMascot from "@/assets/images/lucid-mascot.png";
import { submitContactUs, verifyCaptcha } from "@/services/api/Master";
import { useReCaptcha } from "next-recaptcha-v3";

gsap.registerPlugin(ScrollTrigger);

const Subscribe = () => {
  const [loadingSubscribe, setLoadingSubscribe] = useState(false);
  const subscribeAisha = useRef(null);

  const { executeRecaptcha } = useReCaptcha();

  if (subscribeAisha.current) {
    gsap.to(subscribeAisha?.current, {
      scrollTrigger: {
        trigger: "#subscribe",
        scrub: true,
        start: "top 80%",
        end: "303px 80%",
      },
      translateX: "-144px",
    });
  }

  const handleSubscribe = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        const email = event.target.email.value;
        const first_name = event.target.first_name.value;
        const last_name = event.target.last_name.value;
        const thisYear = new Date().getFullYear();

        setLoadingSubscribe(true);

        const token = await executeRecaptcha("form_subscribe");
        const response = await verifyCaptcha(token);

        if (response.data.score > 0.5) {
          const payload = {
            email,
            tags: ["Customer", String(thisYear), "Web"],
          };

          if (first_name) {
            payload.first_name = first_name;
          }

          if (last_name) {
            payload.last_name = last_name;
          }

          try {
            const res = await subscribe(payload);
            toast.success("You are successfully subscribed to our newsletter!");
            event.target.reset();
          } catch (error) {
            if (error.response.data.message == "User is already subscribed.") {
              toast(error.response.data.message);
            }
          }
          setLoadingSubscribe(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [executeRecaptcha]
  );

  return (
    <div id='subscribe' className='relative overflow-hidden lg:py-20 isolate'>
      <div className='absolute top-0 w-full z-10 h-[0.8px] bg-gradient-to-r from-background via-yellow-1 to-background'></div>
      <div className='relative items-center max-w-5xl px-4 py-10 mx-auto space-y-6 lg:py-12 lg:rounded-md lg:space-y-0 lg:space-x-10 lg:flex lg:px-14 bg-gradient-to-tr from-orange-1 to-red-1'>
        <div className='flex-1'>
          <h2 className='text-4xl font-bold tracking-tight text-white sm:text-5xl'>
            Subscribe To Our Newsletter
          </h2>
          <p className='mt-4 lg:mt-6 text-white/80'>
            {
              "Stay up to date with the latest news, special reports, videos, infobytes, and features on the region's most notable entrepreneurial ecosystems"
            }
          </p>
        </div>
        <form
          disabled={loadingSubscribe}
          onSubmit={handleSubscribe}
          className='flex flex-col w-full max-w-sm mx-auto gap-x-4 gap-y-4'
        >
          <div className='w-full'>
            <label htmlFor='email-address' className='sr-only'>
              Email address
            </label>
            <input
              id='email-address'
              name='email'
              type='email'
              autoComplete='email'
              required
              className='w-full min-w-0 flex-auto placeholder:text-white/70 rounded-md border-0 bg-white/20 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6'
              placeholder='Email'
              disabled={loadingSubscribe}
            />
          </div>
          <div className='flex justify-between w-full gap-x-2'>
            <div className='w-full'>
              <label htmlFor='first-name' className='sr-only'>
                First Name
              </label>
              <input
                id='first-name'
                name='first_name'
                type='text'
                className='w-full min-w-0 flex-auto placeholder:text-white/70 rounded-md border-0 bg-white/20 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6'
                placeholder='First Name'
                disabled={loadingSubscribe}
              />
            </div>
            <div className='w-full'>
              <label htmlFor='last-name' className='sr-only'>
                Last Name
              </label>
              <input
                id='last-name'
                name='last_name'
                type='text'
                className='w-full min-w-0 flex-auto placeholder:text-white/70 rounded-md border-0 bg-white/20 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6'
                placeholder='Last Name'
                disabled={loadingSubscribe}
              />
            </div>
          </div>
          <button
            type='submit'
            className='flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white mt-2'
            disabled={loadingSubscribe}
          >
            {loadingSubscribe ? "Loading..." : "Subscribe Now"}
          </button>
        </form>
        <div
          ref={subscribeAisha}
          className='hidden lg:block -z-10 top-1/2 -translate-y-1/2 flex-shrink-0 absolute w-40 left-0 rotate-[-32deg] aspect-[199/320]'
        >
          <Image
            src={LucidMascot.src}
            alt=''
            layout={"fill"}
            objectFit={"cover"}
          />
        </div>
      </div>
      <svg
        viewBox='0 0 1024 1024'
        className='hidden lg:block absolute left-1/2 top-1/2 -translate-y-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]'
        aria-hidden='true'
      >
        <circle
          cx={512}
          cy={512}
          r={512}
          fill='url(#8d958450-c69f-4251-94bc-4e091a323369)'
          fillOpacity='0.4'
        />
        <defs>
          <radialGradient id='8d958450-c69f-4251-94bc-4e091a323369'>
            <stop stopColor='#fdaf3a' />
            <stop offset={1} stopColor='#fdaf3a' />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Subscribe;
